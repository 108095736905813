import { default as indexMW2pTGKYw9Meta } from "/www/frontend/pages/[category]/[post]/index.vue?macro=true";
import { default as indexjuyX0q9GhIMeta } from "/www/frontend/pages/[category]/index.vue?macro=true";
import { default as categoriesiz6SlRCl11Meta } from "/www/frontend/pages/categories.vue?macro=true";
import { default as cookiesFokYCo7moJMeta } from "/www/frontend/pages/cookies.vue?macro=true";
import { default as fetchZvR3aCHUPmMeta } from "/www/frontend/pages/fetch.vue?macro=true";
import { default as indexBDTiH6CxabMeta } from "/www/frontend/pages/index.vue?macro=true";
import { default as loginMYipddRzXhMeta } from "/www/frontend/pages/login.vue?macro=true";
import { default as profileKDvleGqvgoMeta } from "/www/frontend/pages/profile.vue?macro=true";
import { default as registere4T82dylAzMeta } from "/www/frontend/pages/register.vue?macro=true";
import { default as submit_oldLiwT2pdYGHMeta } from "/www/frontend/pages/submit_old.vue?macro=true";
import { default as submitdp5H9WLaqMMeta } from "/www/frontend/pages/submit.vue?macro=true";
import { default as testjrrfcehRSHMeta } from "/www/frontend/pages/test.vue?macro=true";
export default [
  {
    name: "category-post",
    path: "/:category()/:post()",
    component: () => import("/www/frontend/pages/[category]/[post]/index.vue")
  },
  {
    name: "category",
    path: "/:category()",
    component: () => import("/www/frontend/pages/[category]/index.vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/www/frontend/pages/categories.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/www/frontend/pages/cookies.vue")
  },
  {
    name: "fetch",
    path: "/fetch",
    component: () => import("/www/frontend/pages/fetch.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/www/frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginMYipddRzXhMeta || {},
    component: () => import("/www/frontend/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/www/frontend/pages/profile.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registere4T82dylAzMeta || {},
    component: () => import("/www/frontend/pages/register.vue")
  },
  {
    name: "submit_old",
    path: "/submit_old",
    component: () => import("/www/frontend/pages/submit_old.vue")
  },
  {
    name: "submit",
    path: "/submit",
    component: () => import("/www/frontend/pages/submit.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/www/frontend/pages/test.vue")
  }
]