import payload_plugin_HKEeQnjDsT from "/www/frontend/node_modules/.pnpm/@pinia+nuxt@0.6.1_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_yJ3vfzlgRd from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VJhDd7PICE from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tHNbshWpnz from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kH5JGcDIAr from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3YLKzQNV9w from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SbboMG6HXo from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3JDpUigHAA from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9NPvMMgb0L from "/www/frontend/node_modules/.pnpm/@pinia+nuxt@0.6.1_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/www/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_XU3TBUQXmP from "/www/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.13.0_jiti@2.4.1__ioredis@5._dey4fak2nwqfotvd3r5u5n3stu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_txYXxYftVa from "/www/frontend/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_VkVsdLKCa3 from "/www/frontend/node_modules/.pnpm/nuxt-auth-sanctum@0.5.2_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import slideovers_aaNiP3MPsz from "/www/frontend/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vite@5.4.11_@_43iho7q2ry5k5drxkjdkmmgw6e/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_PWMnTF9NMu from "/www/frontend/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vite@5.4.11_@_43iho7q2ry5k5drxkjdkmmgw6e/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_FalJcYAbgV from "/www/frontend/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vite@5.4.11_@_43iho7q2ry5k5drxkjdkmmgw6e/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ZG7qFIpc9r from "/www/frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_kgla3y0rRf from "/www/frontend/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.80.6_ter_eivikah2efb6d76igekeuozbve/node_modules/@nuxt/icon/dist/runtime/plugin.js";
export default [
  payload_plugin_HKEeQnjDsT,
  revive_payload_client_yJ3vfzlgRd,
  unhead_VJhDd7PICE,
  router_tHNbshWpnz,
  payload_client_kH5JGcDIAr,
  navigation_repaint_client_3YLKzQNV9w,
  check_outdated_build_client_SbboMG6HXo,
  chunk_reload_client_3JDpUigHAA,
  plugin_vue3_9NPvMMgb0L,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XU3TBUQXmP,
  plugin_client_txYXxYftVa,
  plugin_VkVsdLKCa3,
  slideovers_aaNiP3MPsz,
  modals_PWMnTF9NMu,
  colors_FalJcYAbgV,
  plugin_client_ZG7qFIpc9r,
  plugin_kgla3y0rRf
]